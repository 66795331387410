import React from 'react';
import HeaderBar from '../Components/Navigation/HeaderBar.js';
import { useEffect, useState } from 'react';
import { useUrlSearchParams } from 'use-url-search-params';
import LandingPage from '../Components/Landing Page/landing-page.js';
import Footer from '../Components/Navigation/Footer.js';
import ContentContext from '../Context/contentContext.js';
import AboutModal from '../Components/AboutDash/AboutModal.js';
import AboutReachUs from '../Components/AboutDash/AboutReachUs.js';
import ModalContext from '../Context/modalContext.js';
import TeamPageContext from '../Context/teamPageContext.js';
import MeetTeam from '../Components/AboutDash/MeetTeam.js';
import SearchBar from '../Components/Navigation/Menu/SearchBar.js';
import '../App.css';
import { useOktaAuth } from '@okta/okta-react';
import { UserTrackingProvider } from '../Context/trackingContext.js';

const componentStyles = {
  app: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  pageTop: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  landingPage: {
    overflow: 'auto',
  },
  headerBar: {
    position: 'sticky',
  },
  footer: {
    display: 'block',
    position: 'absolute',
    bottom: 0,
  },
  iframe: {
    // height: "100%",
    width: '100%',
    border: 'none',
  },
  bodyContainer: {
    height: '95%',
  },
};

export const LandingPageComponent = ({ classes }) => {
  // const { global$ } = useContext(QlikContext);
  // const auth = useUserName(global$); // this was get authenticated user
  const [currApp, setCurrApp] = useState(null);
  const [isMenuExpanded, setIsMenuExpanded] = useState(true);
  const [params, setParams] = useUrlSearchParams({ app: null });
  const { authState, oktaAuth } = useOktaAuth();
  const [loggedInUser, setLoggedInUser] = useState();

  useEffect(() => {
    if (params.app && params.app !== 'home') {
      setCurrApp(decodeURIComponent(params.app));
    } else {
      setCurrApp(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.app]);
  useEffect(() => {
    if (currApp !== null) setIsMenuExpanded(false);
    else setIsMenuExpanded(true);
  }, [currApp]);

  useEffect(() => {
    if (!authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
    } else {
      oktaAuth.token.getUserInfo().then((info) => {
        setLoggedInUser(info);
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const [modalData, setModalData] = useState({
    viewDataSource: false,
    meetOurTeam: false,
    reachUs: false,
    pushReports: false,
    search: false,
  });

  useEffect(() => {
    // console.log(modalData);
  }, [modalData]);

  const updateModalData = (key) => {
    Object.keys(modalData).forEach((v) => (modalData[v] = false));
    let tempdata = modalData;
    setModalData((prevState) => ({
      ...tempdata,
      [key]: true,
    }));
  };

  const host = window.location.host;
  const isStaging = host.includes('stag') || host.includes('localhost');
  const search = window.location.search;
  const queryParams = new URLSearchParams(search);
  const queryParamEmail = queryParams.get('email');

  return (
    <>
    <UserTrackingProvider>
      <div className={classes.pageTop}>
        <HeaderBar
          className={classes.headerBar}
          isExpanded={isMenuExpanded}
          setExpandMenu={setIsMenuExpanded}
          appOpen={currApp !== null}
          currApp={currApp}
          modelData={modalData}
          updateModalData={updateModalData}
          userEmail={
            isStaging && queryParamEmail ? queryParamEmail : loggedInUser?.email
          }
        />
        <div className={classes.bodyContainer}>
          {modalData.viewDataSource ? (
            <AboutModal
              isOpen={modalData.viewDataSource}
              updateModalData={updateModalData}
              key={'viewDataSource'}
            />
          ) : null}
          {modalData.meetOurTeam ? (
            <MeetTeam
              isOpen={modalData.meetOurTeam}
              updateModalData={updateModalData}
              key={'meetOurTeam'}
            />
          ) : null}
          {modalData.reachUs ? (
            <AboutReachUs
              isOpen={modalData.reachUs}
              updateModalData={updateModalData}
              key={'reachUs'}
            />
          ) : null}
          {/* {modalData.pushReports ? (
          <SubscriptionModule
            isOpen={modalData.pushReports}
            updateModalData={updateModalData}
            key={'pushReports'}
          />
        ) : null} */}
          {modalData.search ? (
            <SearchBar
              isOpen={modalData.search}
              updateModalData={updateModalData}
              key={'search'}
            />
          ) : null}
          {currApp ? (
            <div className="iframe-container">
              <iframe
                title="Content"
                src={currApp}
                frameBorder="0"
                className={classes.iframe}
              />
            </div>
          ) : (
            <LandingPage
              className={classes.landingPage}
              modelData={modalData}
              updateModalData={updateModalData}
              // userName={auth.user}
              userName={loggedInUser?.email}
            />
          )}
        </div>

        {/* </ModalContext.Provider> */}
        <Footer className={classes.footer} />
      </div>
      <div className={classes.pageBottom}></div>
    </UserTrackingProvider>
    </>
  );
};
