import React, { useContext, useMemo, useRef } from "react";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import ContentContext from "../../../Context/contentContext.js";
import classNames from "classnames";
import externalLinkIcon from "../../../Assets/Icon/open_in_new-24px.svg";
import { useUrlSearchParams } from "use-url-search-params";
import urlBuild from "../../../utils/urlBuild.js";
import moment from "moment-timezone";
import { TrackingContext } from "../../../Context/trackingContext.js";

const componentStyles = {
  menuOuterContainer: {
    position: "relative",
  },
  menuInnerContainer: {
    position: "absolute",
    backgroundColor: "white",
    opacity: "0.97",
    boxShadow: "2px 8px 10px rgba(100, 104, 127, .1)",
    borderRadius: "5px",
    marginTop: "14px",
    zIndex: 1,
  },
  subMenuContent: {
    color: "black",
    fontWeight: 400,
    fontSize: "12px",
    display: "flex",
    //flexFlow: "wrap"
    flexDirection: "row",
  },
  linkStyling: {
    color: "#4A4A4A",
    fontWeight: 400,
    textDecoration: "none",
    cursor: "pointer",
    padding: "5px 0px",
    textTransform: "none",
    fontSize: "12px",
    "&:hover": {
      color: "#000000",
      "& .external-link": { opacity: 1 },
    },
  },
  noAccess: {
    color: "#aaa",
    cursor: "default",
    "&:hover": { color: "#aaa" },
  },
  subMenuLabel: {
    fontWeight: 600,
    color: "black",
    fontSize: "12px",
    whiteSpace: "nowrap",
    paddingRight: "20px",
    // minWidth: "130px"
  },
  subMenuSection: {
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    listStyleType: "none",
  },
  verticalMenu: {
    padding: "20px 0px 0px 20px",
    display: "flex",
    flexDirection: "column",
    "&:last-child div": {
      borderRight: "0px",
    },
    minWidth: "175px",
  },
  listContainer: {
    // borderRight: "1px solid #E1E1E1",
    marginTop: "20px",
    height: "100%",
    padding: "0px 10px 20px 0px",
  },
  linkLabel: {
    display: "inline-block",
    //width: "calc(100% - 16px)",
  },
  externalLink: {
    width: "16px",
    height: "16px",
    float: "right",
    backgroundImage: `url(${externalLinkIcon})`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
    opacity: 0,
  },
};

const SectionMenu = ({ classes, apps }) => {
  const wrapperRef = useRef();
  const { currentUserInfo, saveLogs } = useContext(TrackingContext);

  let appsSorted = apps.sort(
    (a, b) => parseInt(a.sectionSort) - parseInt(b.sectionSort)
  );

  const mashSectioned = appsSorted.reduce(
    (mash, { title, section, url, key, useClass, hasAccess }) => {
      if (!mash[section]) mash[section] = [];
      mash[section].push({ title, url, section, key, useClass, hasAccess });

      return mash;
    },
    {}
  );
  const sections = Object.keys(mashSectioned);

  const { setCurrApp } = useContext(ContentContext);
  const [params, setParams] = useUrlSearchParams({ app: null });

  React.useEffect(() => {
    if (params.app && params.app !== "home") {
      setCurrApp(decodeURIComponent(params.app));
    } else {
      setCurrApp(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.app]);

  React.useEffect(() => {
    if (wrapperRef.current) {
      const clientRect = wrapperRef.current.getBoundingClientRect()
      const elemRight = clientRect.right;
      if (elemRight > window.innerWidth) {
        const difference = elemRight - window.innerWidth;
        
        wrapperRef.current.style.marginLeft = `-${difference + 10}px`;
      }
    }
  }, [])

  return (
    <div className={classes.menuOuterContainer} data-menu-box>
      <div className={classes.menuInnerContainer} ref={wrapperRef}>
        <div className={classes.subMenuContent}>
          {sections.map((section, i) => {
            const links = mashSectioned[section];

            return (
              <div key={i} className={classes.verticalMenu}>
                <div className={classes.subMenuLabel}>{section}</div>
                <div className={classes.listContainer}>
                  <ul className={classes.subMenuSection}>
                    {links.map((link, j) => {
                      // const [_, path] = link?.url?.split("dash.condenast.com");
                      const urlWithPrefix = link.url; // urlBuild({ path });
                      return (
                        <li
                          key={link.title}
                          className={classNames(classes.linkStyling, {
                            [classes.noAccess]: !link.hasAccess,
                          })}
                        >
                          <div
                            className={classNames(classes.linkLabel, {
                              [classes.noAccess]: !link.hasAccess,
                            })}
                            onClick={
                              link.hasAccess
                                ? () => {
                                  const currentUtcTime = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                                  const {fullName, userEmail, designation} = currentUserInfo;
                                  saveLogs({data: [fullName, userEmail, designation, 'link-click', urlWithPrefix, currentUtcTime, link.title]})
                                  if (
                                    window.parent &&
                                    window.parent.dataLayer
                                  ) {
                                    window.parent.dataLayer.push({
                                      event: `datavisualization-dashhomepage-click_menulink`,
                                    });
                                  }
                                  //setParams({ app: urlWithPrefix });
                                  window.open(urlWithPrefix, '_blank')
                                }
                                : () => {
                                  if (
                                    window.parent &&
                                    window.parent.dataLayer
                                  ) {
                                    window.parent.dataLayer.push({
                                      event: `datavisualization-dashhomepage-click_menulink`,
                                    });
                                  }
                                }
                            }
                          >
                            {link.title}
                          </div>
                          {/* {link.hasAccess ? (
                            <a
                              className={classNames(
                                classes.externalLink,
                                "external-link"
                              )}
                              href={urlWithPrefix}
                              target="_blank"
                            />
                          ) : null} */}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

SectionMenu.propTypes = {
  styles: PropTypes.object,
  apps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      section: PropTypes.string.isRequired,
    })
  ),
};

export default withStyles(componentStyles)(SectionMenu);