export const appendValuesToSheet = ({ sheetId, data }) => {
  try {
    fetch(`/api/google-sheet/${sheetId}/Sheet1!A2:Z`, {
      method: 'POST',
      // headers: headers,
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          console.log(`Error fetching google sheet data: ${response.status}`);
        }
        if (response.status === 200) {
          console.log("log updated");
        }
      })
      .then((data) => {
        if (data) {
          console.log('data ', data);
        }
      });
  } catch (error) {
    console.log(error);
  }
};
