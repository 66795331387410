
import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import withStyles from "react-jss";
import PropTypes from "prop-types";
import avatar from "../../Assets/Icon/avatar.png";
import Modal from '../Modal.js';
const componentStyles = {
    leftSide: {
        width: "100%",
    },
    header: {
        fontSize: '24px',
        padding: '30px 15px',
        display: 'flex',
        backgroundColor: 'black',
        color: 'white',
        fontWeight: 400,
        fontStyle: 'italic',
        justifyContent: "flex-end"
    },
    bodyText: {
        alignSelf: "flex-end",
        fontFamily: ["Gotham", "sans-serif"],
        color: '#4a4a4a',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '24px',

    },
    para: {
        fontSize: "12px",
        lineHeight: "20px",
        paddingLeft: '19px',
    },
    rightSide: {
        width: "100%",
        height: "100%",
        overflowY: "auto",
        outline: 'none',
        justifyContent: "center",
        alignItems: "center",
    },
    teamHeading: {
        color: "#000000",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: "100%",
        marginTop: "10px",
        marginBottom: "15px",
        letterSpacing: "0.01em",
        fontFamily: 'Rubik',
        fontStyle: "italic",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "28px",

    },
    teamPhotoContainer: {
        display: "flex",
        flexFlow: "wrap",
        flex: 1,
        justifyContent: "center",
        maxWidth: "100%",
        minWidth: " 100%",
    },
    tileContainer: {
        width: "162px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        marginBottom: "5px",
        textAlign: "center",
        alignItems: "center",

    },
    photoCircle: {
        width: "100%",
        maxWidth: "100%",
    },
    nameHolder: {
        textAlign: "center",
        placeHoder: "center",
        justifyContent: "center",
        alignItems: "center",
        height: "40px",
        width: "172px",
        // width: "140px",
    },
    name: {
        textAlign: "center",
        alignItems: "center",
        fontWeight: 600,
        fontFamily: 'Rubik',
        fontStyle: "normal",
        fontSize: "12px",
        lineHeight: "14px",
    },
    bio: {
        width: "140px",
        textAlign: "center",
        alignItems: "center",
        fontWeight: 400,
        fontFamily: 'Rubik',
        fontStyle: "normal",
        fontSize: "10px",
        lineHeight: "12px",
        color: "#000000",
    },
    imageContainer: {
        width: "100px",
        height: "100px",
        padding: "10px",
        backgroundColor: "#ccc",
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        overflow: "hidden",
        backgroundPosition: "center",
        backgroundSize: "cover",
    }
};
function MeetTeam({ classes, isOpen, updateModalData, key }) {
    const [teamData, setTeamData] = useState("");
    const [TeamApiCompleted, setTeamApiCompleted] = useState(false);
    const rightRef = useRef(null);
    useEffect(() => {
        if (rightRef.current) {
            rightRef.current.focus();
        }
    }, []);

    useEffect(() => {
        axios.get("/api/google-sheet/1-1AK4V6cHtTpul-X6QD3vtFO2-RMGK9DLqqdQPj9r78/Team%20Profiles!A2:Z").then((data) => {
            let formattedTeamData = data.data;
            formattedTeamData = data.data.map((item) => {
                return {
                    name: item[0],
                    geo: item[1],
                    photo: item[2],
                    image: item[3],
                    kb: item[4] 
                }
            })
            setTeamData(formattedTeamData);
            setTeamApiCompleted(true);
        });
    }, [])
    const data = [...teamData]
    const location = [...new Set(data.map((item) => item?.geo?.toUpperCase()))];

    const leftSide = (
        <div className={classes.leftSide}>
            <div className={classes.header}>
                Meet the BI Team
            </div>
            <div className={classes.bodyText}>
                <p className={classes.para}>
                    The Business Intelligence team is a part of the global Data
                    Intelligence Team.
                    The BI team focuses on Dash engineering and
                    design, data visualization, and data products for both internal
                    and external stakeholders.
                    Meet the team!
                </p>

            </div>
        </div>
    );
    const rightSide = (
        <div className={classes.rightSide} ref={rightRef}>
            {location.map(geo => {
                return <div key={geo}>
                    <div className={classes.teamHeading} >{geo}</div>
                    <div className={classes.teamPhotoContainer}>
                        {data.filter(d => d.geo.toUpperCase() === `${geo}`).map((d, i) => {
                            return (
                                <div key={i} className={classes.tileContainer} >

                                    <div className={classes.imageContainer} style={{ backgroundImage: `url(${d.photo ? d.photo : null})` }}>
                                    </div>

                                    <div className={classes.nameHolder}>
                                        <div className={classes.name}>{d.name}</div>
                                    </div>
                                    {/* <div className={classes.bio}>
                                                            {d.bio}
                                                        </div> */}

                                </div>
                            );
                        })}
                    </div>
                </div>
            }
            )}
        </div>
    );
    return (
        <>
            {TeamApiCompleted ?
                <Modal
                    isOpen={isOpen}
                    splitBox={true}
                    children={{ leftBox: leftSide, rightBox: rightSide }}
                    onClose={() => {
                        updateModalData(key);
                    }}
                />
                : null}
        </>
    );
};

MeetTeam.propTypes = {
    children: PropTypes.node,
    styles: PropTypes.object,
};

export default withStyles(componentStyles)(MeetTeam);










