import React, { useState, useContext, useEffect } from "react";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuBar from "./Menu/MenuBar.js";
import dashLogo from "../../Assets/Dash3Logo.png"
import ContentContext from "../../Context/contentContext.js";
import { useUrlSearchParams } from "use-url-search-params";
import { TrackingContext} from "../../Context/trackingContext.js";
import { appendValuesToSheet } from '../../Hooks/appendValuesToSheet.js';
import moment from 'moment';

const componentStyles = {
  logoContainer: {
    display: "flex",
    padding: "10px 5px",
    flexDirection: "column",
    cursor: "pointer",
    position: "relative",
  },
  cnLogo: {
    height: "10px",
    padding: "5px 10px 0px 10px",
  },
  dashLogo: {
    height: "25px",
  },
  dashLogoSm: {
    height: "15px",
    postion: "absolute",
    left: "50px",
    top: "11px",
  },
  headerBar: {
    display: "flex",
    background: "white",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
  },
  noshow: {
    color: "white",
  },
  menuIconBox: {
    display: "inline-block",
    height: "40px",
    overflow: "hidden",
    width: "50px",
  },
  menuIcon: {
    padding: "10px 10px 10px 24px",
    width: "40px",
    cursor: "pointer",
    fontSize: "1.2em !important",
  },
  subMenuTitle: {
    fontSize: "24px",
    lineHeight: "45px",
    fontWeight: "bold",
    position: "absolute",
    left: "130px",
  },
  menuBar: {
    background: "white",
  },
  menuBarSubDiv: {
    boxShadow: "0px 4px 25px -5px rgba(0, 0, 0, .2)",
  },
  headerContainer: {
    position: "sticky",
    top: 0,
    zIndex: 10,
   
  },
};

const HeaderBar = ({
  classes,
  isExpanded = true,
  setExpandMenu,
  appOpen = false,
  currApp,
  modelData, 
  updateModalData,
  userEmail,

}) => {
  const toggle = () => setExpandMenu(!isExpanded);
  const [currMenuTitle, setCurrMenuTitle] = useState(null);
  const { setCurrApp } = useContext(ContentContext);
  const [params, setParams] = useUrlSearchParams({ app: null });
  const {setUserInfo, saveLogs} = useContext(TrackingContext);

  useEffect(() => {
    if (userEmail) {
      async function logLoggedinUserDetails() {
        const data = await fetch(`/api/user/designation/${userEmail}`);
        const userInfo = await data.json();
        const { designation, fullName } = userInfo;
        if (designation && fullName) {
          const currentUtcTime = moment.utc().format('YYYY-MM-DD HH:mm:ss');
          setUserInfo({userEmail, fullName, designation});
          saveLogs({data: [fullName, userEmail, designation, 'page-load', 'NA', currentUtcTime, 'NA']})
        }
      }
      logLoggedinUserDetails();
    }
  }, [userEmail]);

  React.useEffect(() => {
    if (params.app && params.app !== "home") {
      setCurrApp(decodeURIComponent(params.app));
    } else {
      setCurrApp(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.app]);
 
  return (
    <div className={classes.headerContainer}>
      <div className={classes.headerBar}>
        <div>
          <div className={classes.menuIconBox}>
            <FontAwesomeIcon
              className={classes.menuIcon}
              onClick={toggle}
              icon="bars"
            />
          </div>
        </div>
        <div
          className={classes.logoContainer}
          onClick={() => setParams({ app: "home" })}
        >
          {!appOpen ? (
            <img className={classes.dashLogo} src={dashLogo} alt="dash logo"  
            />
          ) : (
            <img
              className={classes.dashLogoSm}
              src={dashLogo}
              alt="dash logo"
            />
          )}
        </div>
        {appOpen ? (
          <div className={classes.subMenuTitle}>{currMenuTitle}</div>
        ) : null}
        <div className={classes.noshow}>{"-"}</div>
      </div>
      <div className={classes.menuBar}>
        <div className={classes.menuBarSubDiv}>
          {
            userEmail ? (
              <MenuBar
                currApp={currApp}
                setCurrMenuTitle={setCurrMenuTitle}
                isExpanded={isExpanded}
                modelData={modelData}
                updateModalData={updateModalData}
                userEmail={userEmail}
              />
            ) : null
          }
        </div>
      </div>
    </div>
  );
};

HeaderBar.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.object,
  setCurrApp: PropTypes.func,
};

export default withStyles(componentStyles)(HeaderBar);
