import React, { createContext, useState } from 'react';
import { appendValuesToSheet } from '../Hooks/appendValuesToSheet';

export const TrackingContext = createContext();

export const UserTrackingProvider = ({ children }) => {
  const [currentUserInfo, setUserInfo] = useState();
  const host = window.location.host;
  const isStaging = host.includes('stag') || host.includes('localhost');
  const saveLogs = ({ data }) => {
    appendValuesToSheet({
      sheetId: isStaging
        ? '1A-HlYwuwhIdrAxfUEwJrGB1ffxxoIO00lI_NNI5FkVc'
        : '1E3g42oFSF64f_bYC4gq0u3_aODMalUOxuOBo7jJGEfs',
      data,
    });
  };
  return (
    <TrackingContext.Provider
      value={{ currentUserInfo, setUserInfo, saveLogs }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

// export const useUserTrackingContext = () => useContext(TrackingContext);
