import React, {  useState, useEffect } from "react";
import withStyles from "react-jss";
import PropTypes from "prop-types";
import slack from "../../Assets/slack.png";
import confluence from '../../Assets/confluence.png'
import settings from "../../Assets/settings.png";
import axios from "axios";
import Modal from '../Modal.js';

const componentStyles = {
  leftSide:{
    width: "100%",
  },
  rightSide:{
    width: "100%",
  },
  header: {
    fontSize: '24px',
    padding: '30px 15px',
    display: 'flex',
    backgroundColor: 'black',
    color: 'white',
    fontWeight: 400,
    fontStyle: 'italic',
    justifyContent: "flex-end"
  },
 bodyText: {
    marginTop: "-8px",
    fontWeight: 400,
    fontSize: "14px",
    marginLeft:"20px",
    lineHeight: "24px",
    color: "black",
    width: "100%",
    alignSelf: "flex-end",
    fontFamily: ["Rubik","Gotham", "sans-serif"],
  },
  secondaryText: {
    fontSize: "12px",
    lineHeight: "20px",
    marginTop: "50px"
  },
  subHeader: {
    fontSize: "21px",
    fontStyle: "Italic",
    marginTop: "30px",
    marginBottom: "5px",
    fontWeight:400,
    lineHeight:"24.89px",
    fontFamily: "Rubik",
    color:"black"
  },
  dataList: {
    width: "100%"
  },
  flexContainer: {
    marginbottom:"20px",
    display: "flex",
  },
  sideHeading: {
    fontWeight: 700,
    fontSize:"12px",
    fontFamily:"Rubik",
  },
  details: {
    fontSize: "12px",
    lineHeight: "2px"
  },
  description: {
    marginLeft:"5px",
    marginTop:"12px",
    color: "#4a4a4a",
    fontWeight:300,
    fontFamily:"Rubik",
    fontSize:"12px",
  },
  backDrop:{
    background:"#ECECEC",
    borderRadius: "4px",
    padding: "12px 8px 0px 0px",
    width: "fit-content",
    marginBottom: "15px",
    height: "15px",
    "@media (max-width: 1260px)": {
      marginBottom: "4px",
    },
  },
  icon:{
    maxWidth: "16px",
    padding: "0px 5px 0px 7px",
    marginTop:"-7px",
    marginBottom: "-4px"
  },
  linkText: {
    display: "inline",
    position: "sticky",
    color: "#000000",
    fontWeight: 500,
    marginLeft: "5px"

  },
};

const AboutReachUs = ({ classes, isOpen, updateModalData, key }) => {
  const [data, setData] = useState([]);
  const [apiCompleted, setApiCompleted] = useState(false);
  useEffect(() => {
    axios.get("/api/google-sheet/1-1AK4V6cHtTpul-X6QD3vtFO2-RMGK9DLqqdQPj9r78/Slack%20details!A:Z").then((data) => {
      let formattedData = data.data;
      formattedData = formattedData.map((item) => {
        return {
            Name: item[0],
            channel: item[1],
            links: item[2],
            description: item[3]
        }
      } )
      console.log(formattedData)
      setData(formattedData);
      setApiCompleted(true);
    });
  }, [])
  const leftSide = (
    <div className={classes.leftSide}>
      <div className={classes.header}>
        Ways to Reach Us
      </div>
    </div>
  );

  const rightSide = (
    <div className={classes.rightSide}>
      <div style={{ height: "100%",width:"100%", overflowY: "scroll" }}>
        <div className={classes.bodyText} style={{ marginLeft: "0px", }}>
        <div className={classes.subHeader}>
          <div className={classes.details}>
            <div className={classes.backDrop} >
              <img className={classes.icon} src={confluence} alt={"confluence icon"}></img>
                <a className={classes.linkText} style={{ textDecoration: 'none' }} href='https://cnissues.atlassian.net/wiki/spaces/GBIEA/overview?homepageId=15314355578' target="_blank" rel="noreferrer" >Global Business Intelligence & Enterprise Analytics</a>
            </div>
          </div>
        </div>
        <div className={classes.subHeader}>1. THROUGH SLACK</div>
        {data.map((data, idx) => (<div key={idx}>

          <div className={classes.details}>
            <div className={classes.flexContainer}>
              <p className={classes.sideHeading}>{data.Name}: </p>
              <div className={classes.description}>{data.description}</div>
            </div>
            <div className={classes.backDrop}><img className={classes.icon} src={slack} alt={"slack icon"}></img><a className={classes.linkText} style={{ textDecoration: 'none' }} href={data.links} target="_blank" rel="noreferrer">{data.channel}</a></div>

          </div> </div>))
        }
        <div className={classes.subHeader}>2. SERVICE NOW TICKET</div>
        <div className={classes.details}>
          <div className={classes.flexContainer}>
            <p className={classes.sideHeading}>Service Portal{" "}:</p>
          </div>
            <div className={classes.backDrop}><img className={classes.icon} src={settings} alt={"service portal icon"}></img><a className={classes.linkText} style={{ textDecoration: 'none' }} href="https://support.condenastint.com/sp?id=sc_cat_item&sys_id=462176db87f11950876486e09bbb358f" target="_blank" rel="noreferrer">#service portal</a></div>
        </div>

      </div>
      </div>
    </div>
  );

  return (
    <>
      {apiCompleted ?
          <Modal
            isOpen={isOpen}
            splitBox={true}
            children={{ leftBox: leftSide, rightBox: rightSide }}
            onClose={() => {
              updateModalData(key);
            }}
          />
         : null}
    </>
  );
};

AboutReachUs.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.object,
};

export default withStyles(componentStyles)(AboutReachUs);
